<template>
  <div class="wrapper">
    <div slot="header" class="header">
      <div class="dict-title">
        字典名称：<el-tag size="small" type="success">{{ $route.query.dictName }}</el-tag>
      </div>
      <div style="margin-bottom:20px;">
        <el-button v-checkbtn="isShowBtn(AUTH_BTN.dict_value_add_edit)" type="primary" size="small" icon="el-icon-plus" @click="goAdd">新增</el-button>
        <el-button type="info" size="small" icon="el-icon-back" @click="goBack">返回</el-button>
      </div>
    </div>
    <tp-table :tableData="dataItems" :columns="columns" :isPage="0" />
  </div>
</template>

<script>
import { getDictValues, delDictValue } from './api'
import {authBtnMixin} from "@/mixins/authBtnMixin";

const columns = [
  {
    label: '名称',
    minWidth: '200',
    prop: 'enumText',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['enumText']}>
          {row['enumText']}
        </p>
      )
    }
  },
  {
    label: '值',
    minWidth: '200',
    prop: 'enumCode',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['enumCode']}>
          {row['enumCode']}
        </p>
      )
    }
  },
  {
    label: '备注',
    minWidth: '250',
    prop: 'remark',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['remark']}>
          {row['remark']}
        </p>
      )
    }
  },
  {
    label: '操作',
    minWidth: '150',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link v-show={this.isShowBtn(this.AUTH_BTN.dict_value_add_edit)} type="primary" disabled={row['isDefault'] == 1} onClick={() => this.goEdit(row['id'])}>
            编辑
          </el-link>

          <el-link disabled={row['isDefault'] == 1} style="margin-left:10px" type="danger" onClick={() => this.goRemove(row['id'])}>
            删除
          </el-link>
        </div>
      )
    }
  }
]
export default {
  mixins: [authBtnMixin],
  data() {
    return {
      dataItems: [],
      columns
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.queryDictValues(this.$route.query.dictCode)
  },
  methods: {
    goAdd() {
      this.$router.push({ name: 'DictValueAddOrEdit', params: { dictCode: this.$route.query.dictCode } })
    },
    goEdit(id) {
      this.$router.push({ name: 'DictValueAddOrEdit', params: { id: id || undefined, dictCode: this.$route.query.dictCode } })
      console.log(id)
    },
    async goRemove(id) {
      // let res = await delDictValue(id)
      // await this.queryDictValues(this.$route.query.dictCode)
      this.$confirm('此操作将删除该字典值, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delDictValue(id).then(() => {
            this.queryDictValues(this.$route.query.dictCode)
            this.$message.success('删除成功')
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    goBack() {
      this.$router.back()
    },

    async queryDictValues(dictCode) {
      this.dataItems = await getDictValues(dictCode)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  /deep/.one-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
  .dict-title {
    font-weight: bold;
  }
  .el-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /deep/.el-form-item {
      margin-bottom: 0;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
  }
}
</style>
